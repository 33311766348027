<template>
  <div class="row">
    <div class="col-12">
      <ul v-show="categorys.length != 0" class="list-group">
        <li
          class="list-group-item d-flex justify-content-between align-items-center"
          v-for="(cat, i) in categorys"
          :key="i"
        >
          <div>
            <span v-if="edit_category_id !== cat.id_categoria">
              {{ cat.nombre }}
            </span>
            <span
              class="input-container"
              v-if="edit_category_id == cat.id_categoria"
            >
              <input
                class="form-control input-custom"
                type="text"
                v-model="edit_category_name"
                maxlength="30"
                @keypress.enter="editCompany(cat)"
              />
            </span>
          </div>
          <!-- opciones editar/eliminar -->
          <div
            v-if="edit_category_id !== cat.id_categoria"
            class="dropdown dropdown-custom d-flex justify-content-center"
          >
            <button
              class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide"
              type="button"
              data-bs-toggle="dropdown"
            >
              <font-awesome-icon icon="ellipsis-vertical" />
            </button>
            <ul class="dropdown-menu">
              <li>
                <a
                  @click="activeEditCat(cat)"
                  class="dropdown-item"
                  href="javascript:"
                  :content="`Editar categoría &quot;${cat.nombre}&quot;`"
                  v-tippy="{ arrow: true }"
                >
                  <font-awesome-icon class="pe-2 color-main" icon="pen" />
                  Editar
                </a>
              </li>
              <li>
                <a
                  @click="deleteCompany(cat.nombre, cat.id_categoria)"
                  class="dropdown-item"
                  href="javascript:"
                  :content="`Eliminar categoría &quot;${cat.nombre}&quot;`"
                  v-tippy="{ arrow: true }"
                >
                  <font-awesome-icon class="pe-2 color-main" icon="trash-alt" />
                  Eliminar
                </a>
              </li>
            </ul>
          </div>
          <!-- opciones editar (cancelar/Guardar) -->
          <div v-if="edit_category_id == cat.id_categoria">
              <a
                href="javascript:"
                class="fs-5 me-2 btn btn-custom-color-white border"
                @click="edit_category_id = null"
              >
                <font-awesome-icon icon="circle-xmark" class="color-main"/>
              </a>
              <a
                href="javascript:"
                class="fs-5 btn btn-custom-color-white border"
                @click="editCompany(cat)"
              >
              <font-awesome-icon icon="circle-check" class="color-main"/>
            </a>
            </div>
        </li>
      </ul>

      <div v-show="categorys.length == 0" class="nodata-section__wrapper">
        <img
          class="nodata-section__img"
          src="@/assets/img/utilities/question_face.svg"
          alt=""
        />
        <p class="nodata-section__text">Aún no se han registrado empresas</p>
        <div class="nodata-section__button-wrapper">
          <button class="nodata-section__button">+ Crear empresa</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    categorys: {
      type: Array,
    },
  },
  data() {
    return {
      option: "",
      id_modulo: "17",
      edit_category_id: "",
      edit_category_name: "",
    };
  },
  methods: {
    ...mapActions("organigramaModule", [
      "editarEmpresaCategoria",
    ]),
    openOptions(id_) {
      if (this.option == id_) {
        this.outOption;
      } else {
        setTimeout(() => {
          this.option = id_;
        }, 10);
      }
    },
    outOption() {
      if (this.option != "") {
        this.option = "";
      }
    },
    activeEditCat(cat) {
      this.edit_category_id = cat.id_categoria;
      this.edit_category_name = cat.nombre;
      },
    async editCompany() {
      if(this.edit_category_id!=""){
        let data_ = {
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        id_modificador: this.$ls.get("user").id_usuario,
        nombre: this.edit_category_name,
        formato_archivo: "",
        archivo: "",
        id_modulo: this.id_modulo,
        id_categoria: this.edit_category_id 
        }
        await this.editarEmpresaCategoria(data_).then(res=>{
          if(res){
            this.$toast.open({
            message: "Tu empresa ha sido editada correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
          }
          else {
            this.$toast.open({
              message: "Ocurrio un problema al intentar editar una empresa",
              type: "error",
              duration: 0,
              position: "top-right",
            });
          }
        }).finally(()=>{
          this.edit_category_id = "";
          this.edit_category_name = "";
        })
      }
    },
    deleteCompany( name_, id_ ) {
      //console.log("eliminar");
      this.option = "";
      this.$emit(
        "deleteCompany",
        name_,
        id_
      );
    },
  },
};
</script>
