<template>
  <section id="organigrama-empresas">
    <div class="row mt-3">
      <div class="col-12">
        <h3 class="section-head-title ps-0 mb-3">
          Revisa o crea empresas para asociar a los organigramas.
        </h3>
        <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
          <div class="card-body d-flex flex-column mh-700">
            <div class="row d-flex align-items-center my-3">
              <div class="col-12 col-lg-6">
                <h5 class="font-main text-secondary">
                  Lista de empresas creadas
                </h5>
              </div>
              <div class="col-12 col-lg-6">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control input-custom"
                    placeholder="Escribe un nombre para crear una nueva categoría"
                    v-model="new_category_name"
                    @keypress.enter="addCompany()"
                    @click="edit_category_id = null"
                    maxlength="30"
                  />
                  <button
                    @click="addCompany()"
                    class="btn btn-custom-color-white border"
                    type="button"
                  >
                    <font-awesome-icon icon="plus" class="color-secondary" />
                  </button>
                </div>
              </div>
            </div>
            <spinner v-show="show_spinner == true" />
            <OrganigramaEmpresasTable
              :categorys="categoriasEmpresas"
              @deleteCompany="deleteCompany"
            />
          </div>
        </div>
      </div>
    </div>

    <Question
      v-if="open_question_modal"
      :msg="question_modal_msg"
      :hideCancel="false"
      @cancel="cancelQuestion"
      @accept="acceptQuestion"
    />
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import OrganigramaEmpresasTable from "./OrganigramaEmpresasTable.vue";
import Spinner from "../Spinner.vue";
import Question from "../Modales/Question.vue";
export default {
  components: { OrganigramaEmpresasTable, Spinner, Question },
  data() {
    return {
      add_modal: false,
      show_spinner: true,
      open_question_modal: false,
      question_modal_msg: "",
      pagination: {
        actual_page: 1,
        page_count: 0,
        page_range: 3,
        click_handler: this.changePage,
        prev_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
        next_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
        container_class: "users-list__pagination-container",
        page_class: "pagination-item",
      },
      name_category: "",
      editName: false,
      id_company: this.$ls.get("user").empresa[0].id_empresa,
      id_modulo: "17",
      id_category: "",
      new_category_name: "",
    };
  },
  computed: {
    ...mapState("organigramaModule", ["categoriasEmpresas"]),
  },
  created() {
    this.getCategoriaEmpresa();
  },
  methods: {
    ...mapActions("organigramaModule", [
      "getCategoriaForEmpresa",
      "deleteEmpresaCategoria",
      "crearEmpresaCategoria",
      "editarEmpresaCategoria",
    ]),
    async addCompany() {

      let data_ = {
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        id_creador: this.$ls.get("user").id_usuario,
        nombre: this.new_category_name,
        formato_archivo: "",
        archivo: "",
        id_modulo: this.id_modulo
      }
      await this.crearEmpresaCategoria(data_).then(res=>{
        if(res){
          this.$toast.open({
          message: "Tu empresa ha sido creada correctamente",
          type: "success",
          duration: 6000,
          position: "top-right",
        });
        }
        else {
          this.$toast.open({
            message: "Ocurrio un problema al intentar crear una empresa",
            type: "error",
            duration: 0,
            position: "top-right",
          });
        }
      });
      

      this.$emit("add-company");
    },
    async getCategoriaEmpresa() {
      let data_ = {
        id_empresa: this.id_company,
        id_modulo: this.id_modulo,
      };
      await this.getCategoriaForEmpresa(data_);
      this.show_spinner = false;
    },
    closeModal() {
      this.add_modal = false;
      this.editName = false;
    },
    cancelQuestion() {
      this.open_question_modal = false;
    },
    async acceptQuestion() {
      this.show_spinner = true;
      this.open_question_modal = false;
      let data_ = {
        id_categoria: this.id_category,
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        id_eliminador: this.$ls.get("user").id_usuario,
        id_modulo: this.id_modulo,
      };
      await this.deleteEmpresaCategoria(data_).then((res) => {
        if (res) {
          this.$toast.open({
            message: "Tu empresa ha sido eliminada correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
        } else {
          this.$toast.open({
            message: "Ocurrio un problema al intentar eliminar la empresa",
            type: "error",
            duration: 0,
            position: "top-right",
          });
        }
        this.show_spinner = false;
      });
    },
    deleteCompany(name_, id) {
      this.open_question_modal = true;
      this.question_modal_msg = "¿Estás seguro de eliminar ésta empresa?";
      this.name_category = name_;
      this.id_category = id;
    },
  },
};
</script>
